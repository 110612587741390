import type { MangoQuery, MangoQuerySelector, MangoQuerySortPart } from 'rxdb';

import mangoQueryBuilders from '../database/mangoQueryBuilders';
import { makeRandomSortRules, makeRegexSelector } from '../database/queryHelpers';
import { isFirstClassDocumentQuery } from '../database/standardQueries';
import {
  AnyDocument,
  DocumentLocation,
  FeedDocumentLocation,
  SortKey,
  SortOrder,
  SortRule,
  SplitByKey,
} from '../types';
import { DatabaseCollectionNamesToDocType } from '../types/database';
import { notEmpty } from '../typeValidators';
import { isUsingSQLite } from '../utils/environment';
import { negateQuery } from './negateRxDBQuery';
// eslint-disable-next-line import/no-cycle
import { parseFromQuery } from './parser';
import {
  arrayKeys,
  booleanKeys,
  convertStringSearchValue,
  dateKeys,
  getDateTypeQueryFromKey,
  getQueryFromBooleanKey,
  getValueFromDateKey,
  keyMap,
  logicalOperatorMap,
  numberKeys,
  numberTypeOperatorMap,
  queryFromArrayNodeMap,
  queryMakerForNumberKey,
  stringKeys,
  stringKeysThatShouldDefaultExactMatch,
  stringTypeOperatorMap,
} from './rxDBQueryConverterHelpers';
import { AST, ExpressionLogical, ExpressionNode, NodeType, TypeOperator } from './types';

type DatabaseHookOptions<TDocType extends DatabaseCollectionNamesToDocType['documents']> =
  | {
      postProcessData?: (data: DatabaseCollectionNamesToDocType['documents'][]) => TDocType[];
    }
  | undefined;

// - handle random sort on RxDB
export const convertSortRuleToRxDBSort = <
  TDocType extends DatabaseCollectionNamesToDocType['documents'],
>(
  sortRules?: SortRule[],
): {
  mangoQuerySortRules: MangoQuerySortPart<DatabaseCollectionNamesToDocType['documents']>[];
  databaseHookOptions: DatabaseHookOptions<TDocType>;
} => {
  if (!sortRules) {
    return {
      mangoQuerySortRules: [],
      databaseHookOptions: {},
    };
  }

  const databaseHookOptions: DatabaseHookOptions<TDocType> = {};
  const mangoQuerySortRules: MangoQuerySortPart<DatabaseCollectionNamesToDocType['documents']>[] = [];

  sortRules.forEach((sortRule) => {
    if (sortRule.manualOverride) {
      const manualOverrideIds = sortRule.manualOverride;
      databaseHookOptions.postProcessData = (documents) => {
        return manualOverrideIds
          .map((id) => documents.find((doc) => doc.id === id))
          .filter(notEmpty) as TDocType[];
      };
      return;
    }

    const { key, order } = sortRule;

    if (key === SortKey.Random) {
      mangoQuerySortRules.push(...makeRandomSortRules());
      return;
    }

    if (key === SortKey.ReadingProgress) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mangoQuerySortRules.push({ 'rxdbOnly.indexFields.readingPosition_scrollDepth': order });
      return;
    }

    if (key === SortKey.WordCount) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mangoQuerySortRules.push({ 'rxdbOnly.indexFields.length_in_seconds': order });
      return;
    }

    if (key === SortKey.UpdatedAt) {
      // `_meta.lwt` is the last write time internal to RxDB. It gives us a proxy for the updatedAt time.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mangoQuerySortRules.push({ '_meta.lwt': order });
      return;
    }

    if (
      (key === SortKey.Author ||
        key === SortKey.Category ||
        key === SortKey.Title ||
        key === SortKey.Published ||
        key === SortKey.LastStatusUpdate ||
        key === SortKey.LastOpenedAt ||
        key === SortKey.SavedAt) &&
      order === SortOrder.Asc
    ) {
      mangoQuerySortRules.push({ [`rxdbOnly.indexFields.${key}`]: order });
      return;
    }

    // for storages that support iterating indices in reverse order
    if (
      (key === SortKey.Author ||
        key === SortKey.Category ||
        key === SortKey.Title ||
        key === SortKey.Published ||
        key === SortKey.LastOpenedAt) &&
      order === SortOrder.Desc
    ) {
      mangoQuerySortRules.push({ [`rxdbOnly.indexFields.${key}`]: order });
      return;
    }

    // this is to support descending sorts for indices in IndexedDB
    if ((key === SortKey.LastStatusUpdate || key === SortKey.SavedAt) && order === SortOrder.Desc) {
      mangoQuerySortRules.push({ [`rxdbOnly.indexFields.${key}_desc`]: SortOrder.Asc });
      return;
    }

    mangoQuerySortRules.push({ [key]: order });
  });

  return { mangoQuerySortRules, databaseHookOptions };
};

export const convertQueryToRxDBQuery = <TDocType extends DatabaseCollectionNamesToDocType['documents']>({
  query,
  sortRules,
  splitBy,
  splitByValue: valueToSplitBy,
  docIdsToAlwaysGet,
  seenStatusChangedAtThreshold,
  documentLocation,
  feedDocumentLocation,
  extraQuerySelectors,
  skip = 0,
  isDigestView = false,
}: {
  query: string;
  sortRules?: SortRule[];
  splitBy?: SplitByKey;
  splitByValue?: string;
  documentLocation?: DocumentLocation;
  feedDocumentLocation?: FeedDocumentLocation;
  docIdsToAlwaysGet?: string[];
  seenStatusChangedAtThreshold?: number;
  extraQuerySelectors?: MangoQuerySelector<AnyDocument>[];
  skip?: number;
  isDigestView?: boolean;
}):
  | {
      mangoQuery: MangoQuery<DatabaseCollectionNamesToDocType['documents']>;
      databaseHookOptions: DatabaseHookOptions<TDocType>;
      errorMessage: undefined;
    }
  | {
      mangoQuery: undefined;
      databaseHookOptions: undefined;
      errorMessage: string;
    } => {
  const isAllQuery = query === '*';
  const { ast, errorMessage } = parseFromQuery(query);

  if ((!ast || errorMessage) && !isAllQuery) {
    return {
      mangoQuery: undefined,
      databaseHookOptions: undefined,
      errorMessage,
    };
  }

  const manualOverrideSortRule = sortRules?.find((sortRule) => Boolean(sortRule.manualOverride));
  const manualOverrideIds = manualOverrideSortRule?.manualOverride;

  let filterByIdsQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined;

  if (typeof manualOverrideIds !== 'undefined') {
    filterByIdsQuery = {
      id: {
        $in: manualOverrideIds,
      },
    };
  }

  let splitByQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined;
  let documentLocationQuery:
    | MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']>
    | undefined;

  // SplitBy determines what kind of property we are splitting on
  // example: SplitBy == Seen means we will be splitting documents on the seen/unseen property
  // valueToSplitBy is the specific value of the splitBy property we are filtering the documents on
  // If SplitBy is 'seen', the valueToSplitBy might be 'seen' or 'unseen'
  // (This is how it logically works, the actual value of valueToSplitBy might be different per platform (see TODO below) but logically it's what it represents)
  if (valueToSplitBy) {
    if (splitBy === SplitByKey.DocumentLocation) {
      splitByQuery = {
        triage_status: valueToSplitBy as DocumentLocation,
      };
    } else if (splitBy === SplitByKey.Seen) {
      // 'true' | 'false' is the value we use on web as a query string
      // 'seen' | 'unseen' is the value we use on mobile
      // TODO: perhaps we should reconcile this splitByValue to not be different depending on platform
      const filterBySeen = valueToSplitBy === 'true' || valueToSplitBy === FeedDocumentLocation.Seen;
      if (filterBySeen) {
        if (seenStatusChangedAtThreshold) {
          splitByQuery = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'rxdbOnly.indexFields.showInSeenAfter': { $gt: seenStatusChangedAtThreshold },
          };
        } else {
          splitByQuery = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'rxdbOnly.indexFields.firstOpenedAtExists': true,
          };
        }
      } else if (seenStatusChangedAtThreshold) {
        splitByQuery = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'rxdbOnly.indexFields.showInUnseenAfter': { $gt: seenStatusChangedAtThreshold },
        };
      } else {
        splitByQuery = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'rxdbOnly.indexFields.firstOpenedAtExists': false,
        };
      }
    }
    // This is only used in a specific case for Bulk Actions Palette
    // TODO: Possibly re-work this logic to not need this if statement
  } else if (feedDocumentLocation && documentLocation === DocumentLocation.Feed) {
    splitByQuery = {
      firstOpenedAt: {
        $exists: feedDocumentLocation === FeedDocumentLocation.Seen,
      },
    };
  }

  if (documentLocation) {
    documentLocationQuery = {
      triage_status: documentLocation,
    };
  }

  let docIdsToAlwaysGetQuery:
    | MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']>
    | undefined;

  if (docIdsToAlwaysGet) {
    docIdsToAlwaysGetQuery = {
      id: {
        $in: docIdsToAlwaysGet,
      },
    };
  }

  let mainQuery: MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined;

  if (ast && !isAllQuery) {
    try {
      mainQuery = astToRxDBQuery(ast);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        mangoQuery: undefined,
        databaseHookOptions: undefined,
        errorMessage: error.message,
      };
    }
  }

  const defaultExtraQuerySelectors = isDigestView
    ? [{ saved_from_feed_at: { $gt: seenStatusChangedAtThreshold } }]
    : [];

  const mangoQuery: MangoQuery<DatabaseCollectionNamesToDocType['documents']> = {
    selector: mangoQueryBuilders.$or([
      docIdsToAlwaysGetQuery,
      ...(extraQuerySelectors ?? defaultExtraQuerySelectors),
      mangoQueryBuilders.$and([
        isFirstClassDocumentQuery,
        filterByIdsQuery,
        splitByQuery,
        documentLocationQuery,
        mainQuery,
      ]),
    ]),
  };

  const { mangoQuerySortRules, databaseHookOptions } = convertSortRuleToRxDBSort<TDocType>(sortRules);

  if (mangoQuerySortRules) {
    mangoQuery.sort = mangoQuerySortRules;
  }

  mangoQuery.skip = skip;

  return {
    mangoQuery,
    databaseHookOptions,
    errorMessage: undefined,
  };
};

export const getLibraryRxDBQuery = ({
  filterByFeedDocumentLocation,
  filterByDocumentLocation,
  sortRules,
  docIdsToAlwaysGet,
  firstOpenedBefore,
  isDigestView = false,
}: {
  filterByFeedDocumentLocation?: FeedDocumentLocation;
  filterByDocumentLocation?: DocumentLocation;
  sortRules?: SortRule[];
  docIdsToAlwaysGet?: string[];
  firstOpenedBefore?: number;
  isDigestView?: boolean;
}) => {
  const docLocation = filterByDocumentLocation ?? DocumentLocation.New;
  const query = `triage_status:${docLocation}`;
  const isFeed = docLocation === DocumentLocation.Feed;
  const splitBy = isFeed ? SplitByKey.Seen : undefined;
  const splitByValue = isFeed ? filterByFeedDocumentLocation : undefined;

  return convertQueryToRxDBQuery({
    query,
    sortRules,
    splitBy,
    splitByValue,
    docIdsToAlwaysGet,
    isDigestView,
    seenStatusChangedAtThreshold: firstOpenedBefore,
  });
};

function astToRxDBQuery(
  ast: AST,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined {
  if (ast.type === NodeType.Node) {
    return astToRxDBQueryNode(ast);
  }

  return astToRxDBQueryLogical(ast);
}

function convertMainStringKeyQuery(node: ExpressionNode) {
  const key = keyMap[node.key];
  const actualOperator =
    stringKeysThatShouldDefaultExactMatch.has(key) && node.operator === TypeOperator.Fuzzy
      ? TypeOperator.Exact
      : node.operator;
  const isNotOperator = actualOperator === TypeOperator.Not;
  const operator = stringTypeOperatorMap[actualOperator];
  const searchValue = convertStringSearchValue(node);

  if (stringKeysThatShouldDefaultExactMatch.has(key)) {
    if (isNotOperator) {
      return {
        [key]: {
          $ne: searchValue,
        },
      };
    }

    return { [key]: searchValue };
  }

  if (typeof searchValue === 'object') {
    if (isNotOperator) {
      return {
        [key]: {
          $not: searchValue,
        },
      };
    }

    return { [key]: searchValue };
  }

  // When the user is searching for a string, we want to do a case-insensitive search
  const regexObject = makeRegexSelector(searchValue);

  if (isNotOperator) {
    return {
      [key]: {
        $not: regexObject,
      },
    };
  }

  if (operator === '$regex') {
    return { [key]: regexObject };
  }

  return { [key]: { [operator]: searchValue } };
}

function convertStringNode(
  node: ExpressionNode,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> {
  const key = keyMap[node.key];
  const mainQuery = convertMainStringKeyQuery(node);

  // Handle the case where the user has overridden a value
  if (node.key === 'title' || node.key === 'author' || node.key === 'category') {
    return { [`rxdbOnly.indexFields.${node.key}`]: mainQuery[key] };
  }

  return mainQuery;
}

function convertNumberNode(
  node: ExpressionNode,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> {
  if (queryMakerForNumberKey[node.key]) {
    return queryMakerForNumberKey[node.key](node);
  }

  // Optimize `progress__gt:5` (part of the continue reading query)
  if (node.key === 'progress' && node.operator === TypeOperator.GreaterThan && node.value === '5') {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'rxdbOnly.indexFields.startedReading': 1,
    };
  }

  const key = keyMap[node.key];
  const operator = numberTypeOperatorMap[node.operator];
  const query = { [key]: { [operator]: Number(node.value) } };
  return query;
}

function convertArrayNode(
  node: ExpressionNode,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined {
  if (queryFromArrayNodeMap[node.key]) {
    return queryFromArrayNodeMap[node.key](node);
  }
}

function convertBooleanNode(
  node: ExpressionNode,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined {
  return getQueryFromBooleanKey(node);
}

function convertDateNode(
  node: ExpressionNode,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> {
  const key = keyMap[node.key];
  const expectedDate = getValueFromDateKey(node);
  const query = { [key]: getDateTypeQueryFromKey({ expectedDate, operator: node.operator }) };
  return query;
}

function astToRxDBQueryNode(
  node: ExpressionNode,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> | undefined {
  if (stringKeys.includes(node.key)) {
    return convertStringNode(node);
  }

  if (numberKeys.includes(node.key)) {
    return convertNumberNode(node);
  }

  if (arrayKeys.includes(node.key)) {
    return convertArrayNode(node);
  }

  if (booleanKeys.includes(node.key)) {
    return convertBooleanNode(node);
  }

  if (dateKeys.includes(node.key)) {
    return convertDateNode(node);
  }
}

function astToRxDBQueryLogical(
  logical: ExpressionLogical,
): MangoQuerySelector<DatabaseCollectionNamesToDocType['documents']> {
  const operator = logicalOperatorMap[logical.operator];
  const left = astToRxDBQuery(logical.left);
  const right = astToRxDBQuery(logical.right);

  // optimization for query: in:inbox OR in:later
  if (
    operator === '$or' &&
    (left?.triage_status === DocumentLocation.New || left?.triage_status === DocumentLocation.Later) &&
    (right?.triage_status === DocumentLocation.New || right?.triage_status === DocumentLocation.Later)
  ) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return { 'rxdbOnly.indexFields.isNewOrLater': 1 };
  }

  if (operator === logicalOperatorMap.NOT) {
    // We can't use $nor on mobile+desktop because sqlite doesn't support it,
    // so we do an $or and then negate the query value.
    if (isUsingSQLite) {
      return mangoQueryBuilders.$and([
        left,
        mangoQueryBuilders.$or([right ? negateQuery(right) : undefined]),
      ]);
    } else {
      return mangoQueryBuilders.$and([left, mangoQueryBuilders.$nor([right])]);
    }
  }

  return mangoQueryBuilders[operator]([left, right]);
}
