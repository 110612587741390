import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import type { ISearchMatches } from 'shared/foreground/documentSearchEngine';
import { SplitBySeenValues } from 'shared/foreground/models';
import { markDocumentAsOpened } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import getUIFriendlyNameForDocumentLocation from 'shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import listShouldShowDocumentLocation from 'shared/foreground/utils/listShouldShowDocumentLocation';
import type { BaseDocument, FirstClassDocument } from 'shared/types';
import {
  Category,
  DocumentLocation,
  FeedDocumentLocation,
  SortKey,
  SortRule,
  SplitByKey,
} from 'shared/types';
import capitalize from 'shared/utils/capitalize';
import getFormattedDate from 'shared/utils/dates/getFormattedDate';
import getDocumentDomain from 'shared/utils/getDocumentDomain';
import getTimeFromStringOrNumberDate from 'shared/utils/getTimeFromStringOrNumberDate';
import { getReadingTimeDisplay } from 'shared/utils/getTimeToRead';
import { rwSanitizeHtml } from 'shared/utils/rwSanitizeHtml';
import urlJoin from 'shared/utils/urlJoin';

import {
  getSplitByKeyOrSplittingByKeyFromLocation,
  getSplitByValueFromPathname,
} from '../../utils/pathnameHelpers';
import useLocation from '../../utils/useLocation';
import { DocumentListCoverImage } from '../CoverImage/DocumentListCoverImage';
import DocumentActionButtons from '../DocumentActionButtons';
import DocumentFaviconOrIcon from '../DocumentFaviconOrIcon';
import DocumentListDropdown from '../Dropdown/DocumentListDropdown';
import ReadingProgressBar from '../ReadingProgressBar';
import StatusDot from '../StatusDot';
import Tag from '../Tag';
import TimeLeftToRead from '../TimeLeftToRead';
import TimeToListen from '../TimeToListen';
import Tooltip from '../Tooltip';
import TrashActionButton from '../TrashActionButton';
import styles from './DocumentList.module.css';

const TotalTimeToRead = ({ wordCount }: { wordCount: number }) => {
  return <span className={styles.timeToRead}>{getReadingTimeDisplay(wordCount, false)}</span>;
};

const TimeToReadOrListen = ({
  category,
  wordCount,
  readingPercent,
  listeningTimeInSeconds,
}: {
  category: Category;
  readingPercent: number;
  wordCount?: number;
  listeningTimeInSeconds?: number;
}) => {
  let result = null;
  if (category === Category.Video && typeof listeningTimeInSeconds === 'number') {
    result = <TimeToListen listeningTimeInSeconds={listeningTimeInSeconds} />;
  }

  if (readingPercent && readingPercent < 100 && wordCount) {
    result = <TimeLeftToRead readingPercent={readingPercent} wordCount={wordCount} />;
  } else if (wordCount) {
    result = <TotalTimeToRead wordCount={wordCount} />;
  }

  if (result) {
    return (
      <>
        <span className={styles.bulletSeparator} />
        {result}
      </>
    );
  }

  return null;
};

const DocumentListItemWrapper = ({
  children,
  documentPathPrefix,
  id,
  isDeleted,
  linkClasses,
  onMouseEnter,
  timeOfLastMouseMoveRef,
}: {
  children: React.ReactNode;
  documentPathPrefix: string;
  id: BaseDocument['id'];
  isDeleted: boolean;
  linkClasses: string[];
  onMouseEnter: (event: React.MouseEvent, docId: BaseDocument['id']) => void;
  timeOfLastMouseMoveRef: React.MutableRefObject<number>;
}) => {
  const setFocus = useCallback(
    (evt: React.MouseEvent) => {
      /*
        Without this check, pressing down on last row in viewport would cause scroll, then
        the row under the cursor would be focused. If you're changing this, make sure to test
        in a few browsers.
      */
      if (performance.now() - timeOfLastMouseMoveRef.current > 50) {
        return;
      }
      onMouseEnter(evt, id);
    },
    [id, onMouseEnter, timeOfLastMouseMoveRef],
  );

  return isDeleted ? (
    <div className={linkClasses.join(' ')} tabIndex={-1} onMouseEnter={setFocus}>
      {children}
    </div>
  ) : (
    <Link
      className={linkClasses.join(' ')}
      onMouseEnter={setFocus}
      tabIndex={-1}
      to={urlJoin([documentPathPrefix, 'read', id])}
    >
      {children}
    </Link>
  );
};

export const _DocumentListItem = ({
  author,
  category,
  currentSortRule,
  description,
  documentLocation,
  documentPathPrefix,
  faviconUrl,
  firstOpenedAt,
  id,
  isBeingRemoved,
  isDeleted = false,
  isFocused,
  languageCode,
  lastOpenedAt,
  lastStatusUpdate,
  listeningTimeInSeconds,
  onMouseEnter,
  originUrl,
  previewImgUrl,
  publishedDate,
  readingPercent,
  rssSourceName,
  savedAt,
  savedAtHistory,
  scrollPercent,
  searchMatches,
  shouldRenderImmediately = false,
  siteName,
  style,
  tags,
  title,
  updatedAt,
  wordCount,
}: {
  author: string | undefined;
  category: Category;
  currentSortRule?: SortRule;
  description: string;
  documentLocation: DocumentLocation | null;
  documentPathPrefix: string;
  faviconUrl?: BaseDocument['favicon_url'];
  firstOpenedAt?: FirstClassDocument['firstOpenedAt'];
  id: BaseDocument['id'];
  isBeingRemoved: boolean;
  isDeleted?: boolean;
  isFocused: boolean;
  languageCode: BaseDocument['language'];
  lastOpenedAt: BaseDocument['lastOpenedAt'];
  lastStatusUpdate: BaseDocument['last_status_update'];
  listeningTimeInSeconds: number | undefined;
  onMouseEnter: (event: React.MouseEvent, docId: BaseDocument['id']) => void;
  originUrl?: string;
  previewImgUrl: string;
  publishedDate?: FirstClassDocument['published_date'];
  readingPercent: number;
  rssSourceName?: string;
  savedAt: BaseDocument['saved_at'];
  savedAtHistory: BaseDocument['saved_at_history'];
  scrollPercent: number;
  searchMatches?: ISearchMatches;
  shouldRenderImmediately?: boolean;
  siteName?: BaseDocument['site_name'];
  style: React.CSSProperties;
  tags: BaseDocument['tags'];
  title: string;
  updatedAt?: number; // Not `document.updated`. This is only passed on the trash page.
  wordCount: number | undefined;
}) => {
  const location = useLocation() as unknown as Location;
  const { pathname } = location;
  const [isMovingTo, setIsMovingTo] = useState('');
  const [shouldRender, setShouldRender] = useState(shouldRenderImmediately);
  const timeOfLastMouseMoveRef = useRef<number>(0);
  const history = useHistory();

  useEffect(() => {
    const onMouseMove = () => {
      timeOfLastMouseMoveRef.current = performance.now();
    };
    document.addEventListener('mousemove', onMouseMove);
    return () => document.removeEventListener('mousemove', onMouseMove);
  });

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (!shouldRender) {
      timeoutId = setTimeout(() => {
        setShouldRender(true);
      }, 10);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedDateForTooltip = (date: Date, showTime = true) => {
    if (!isValid(date)) {
      return '';
    }
    if (showTime) {
      return `${getFormattedDate(date)} at ${format(date, 'h:mm a')}`;
    }

    return getFormattedDate(date);
  };

  const getDateToShowData = () => {
    let dateToShow;
    let tooltipPrefix;

    if (!currentSortRule) {
      return {
        dateToShow: '',
        dateToShowTooltip: '',
      };
    }

    const sortKey = currentSortRule.key;

    switch (sortKey) {
      case SortKey.Published: {
        if (publishedDate) {
          // Published is a string e.g 2022-06-08
          dateToShow = getTimeFromStringOrNumberDate(publishedDate);
          tooltipPrefix = 'Published';
        }
        break;
      }

      case SortKey.LastOpenedAt:
        dateToShow = lastOpenedAt;
        tooltipPrefix = 'Last opened';
        break;

      case SortKey.LastStatusUpdate:
        dateToShow = lastStatusUpdate;
        tooltipPrefix = 'Last saved';
        break;

      case SortKey.UpdatedAt:
        dateToShow = updatedAt;
        tooltipPrefix = isDeleted ? 'Deleted' : 'Last updated';
        break;

      case SortKey.SavedAt:
      default:
        dateToShow = savedAt;
        tooltipPrefix = 'Saved';
        break;
    }

    if (!dateToShow) {
      return {
        dateToShow: '',
        dateToShowTooltip: '',
      };
    }

    const timestamp = typeof dateToShow === 'string' ? parseInt(dateToShow, 10) : dateToShow;
    const date = new Date(timestamp);
    const showTime = sortKey !== SortKey.Published;

    return {
      dateToShow: getFormattedDate(date),
      dateToShowTooltip: `${tooltipPrefix} on ${getFormattedDateForTooltip(date, showTime)}`,
    };
  };

  const { dateToShow, dateToShowTooltip } = getDateToShowData();
  const titleToShow = title ? title : '[No title]';
  const linkClasses = [styles.rowLink, styles.row];

  if (isMovingTo || isBeingRemoved) {
    linkClasses.push(styles.rowBeingRemoved);
  }

  const classes = [styles.listItem];
  if (isFocused) {
    classes.push(styles.listItemIsFocused);
  }
  if (isDeleted) {
    classes.push(styles.listItemDeleted);
  }

  const splitByKey = getSplitByKeyOrSplittingByKeyFromLocation(location);
  const splitByValue = getSplitByValueFromPathname(pathname);
  const isSplitBySeen = splitByKey === SplitByKey.Seen;
  const isUnseenTab = splitByValue === SplitBySeenValues.unseen.queryParamValue;
  const isSplitBySeenAndInSeenTab = isSplitBySeen && isUnseenTab;
  const isFeedList = pathname.match(
    `/${DocumentLocation.Feed}/(${Object.values(FeedDocumentLocation).join('|')})`,
  );
  const isFeedUnseenTab =
    isFeedList && pathname.match(`/${DocumentLocation.Feed}/${FeedDocumentLocation.New}`);
  const shouldAddOpacityToSeenDocs = isFeedUnseenTab ?? isSplitBySeenAndInSeenTab;
  const showCurrentDocumentLocation = listShouldShowDocumentLocation(pathname) && documentLocation;
  const documentLocationDisplayName = documentLocation
    ? getUIFriendlyNameForDocumentLocation(documentLocation)
    : '';
  const movedToTooltip =
    lastStatusUpdate && documentLocationDisplayName
      ? `Moved to ${capitalize(documentLocationDisplayName)} on ${getFormattedDateForTooltip(
          new Date(parseInt(lastStatusUpdate.toString(), 10)),
        )}`
      : '';

  const _style = useMemo(
    () => ({
      ...style,
      transition: 'opacity 0.2s ease-in-out',
      opacity: shouldAddOpacityToSeenDocs && firstOpenedAt ? 0.5 : 1,
    }),
    [style, firstOpenedAt, shouldAddOpacityToSeenDocs],
  );

  const nameOrDomain = getDocumentDomain({ rssSourceName, siteName, originUrl });
  const showNameOrDomain = nameOrDomain && category !== Category.EPUB && category !== Category.PDF;

  return (
    <li className={classes.join(' ')} id={`document-row-${id}`} style={_style}>
      <DocumentListItemWrapper
        isDeleted={isDeleted}
        documentPathPrefix={documentPathPrefix}
        id={id}
        linkClasses={linkClasses}
        onMouseEnter={onMouseEnter}
        timeOfLastMouseMoveRef={timeOfLastMouseMoveRef}
      >
        <div
          className={`${styles.previewImageContainer} ${shouldRender ? '' : `${styles.hiddenChildren}`}`}
        >
          <StatusDot
            category={category}
            firstOpenedAt={firstOpenedAt}
            saved_at_history={savedAtHistory}
          />
          {shouldRender && <DocumentListCoverImage imageUrl={previewImgUrl} category={category} />}
        </div>
        <div className={styles.content}>
          <div className={styles.titleRow}>
            <span className={styles.title} lang={languageCode}>
              {searchMatches?.title_match ? (
                <span dangerouslySetInnerHTML={{ __html: searchMatches.title_match }} />
              ) : (
                titleToShow
              )}
            </span>
          </div>
          <div className={`${styles.contentRow} ${shouldRender ? '' : `${styles.hiddenChildren}`} `}>
            {shouldRender ? (
              <>
                {!searchMatches?.content_match && (
                  <div className={styles.description} lang={languageCode}>
                    {description || '[No summary]'}
                  </div>
                )}
                <div className={styles.infoRow}>
                  <DocumentFaviconOrIcon category={category} faviconUrl={faviconUrl} />
                  {showNameOrDomain && (
                    <>
                      <span className={styles.truncate}>{nameOrDomain}</span>
                      <span className={styles.bulletSeparator} />
                    </>
                  )}
                  <span className={styles.author}>
                    {searchMatches?.author_match ? (
                      <span dangerouslySetInnerHTML={{ __html: searchMatches.author_match }} />
                    ) : (
                      author || 'Unknown'
                    )}
                  </span>
                  <TimeToReadOrListen
                    category={category}
                    readingPercent={readingPercent}
                    wordCount={wordCount}
                    listeningTimeInSeconds={listeningTimeInSeconds}
                  />
                  <div className={styles.tags}>
                    {tags && Object.keys(tags).length > 0 && <span className={styles.bulletSeparator} />}
                    {map(tags, ({ name, type }) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <span
                        className={styles.tag}
                        key={name}
                        onClick={(e) => {
                          // :/ We should be using an anchor but you can't have an anchor within an anchor
                          e.preventDefault();
                          e.stopPropagation();
                          history.push(`/filter/tag:"${encodeURIComponent(name)}"`);
                        }}
                      >
                        <Tag hasHoverStyle>{type === 'generated' ? `#${name}` : name}</Tag>
                      </span>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.itemPlaceholder} />
            )}
          </div>
          {searchMatches?.content_match && (
            <div
              className={styles.searchPreviewText}
              dangerouslySetInnerHTML={{ __html: rwSanitizeHtml(searchMatches.content_match, category) }}
            />
          )}
          <div className={styles.progressBarContainer}>
            <ReadingProgressBar progress={readingPercent} currentScroll={scrollPercent} />
          </div>
        </div>
        <div className={styles.actionsSidebar}>
          {shouldRender && (
            <>
              <div className={styles.date}>
                {showCurrentDocumentLocation && (
                  <Tooltip content={movedToTooltip}>
                    <span className={styles.currentDocumentLocation}>
                      {documentLocationDisplayName.toLocaleUpperCase()}
                    </span>
                  </Tooltip>
                )}
                {showCurrentDocumentLocation && dateToShow && (
                  <span className={styles.bulletSeparator} />
                )}
                <Tooltip content={dateToShowTooltip}>
                  <span>{dateToShow}</span>
                </Tooltip>
              </div>
              <div className={styles.actionsContainer}>
                {isFocused && !isDeleted && (
                  <DocumentListDropdown triggerClassName={styles.triggerClassName} docId={id} />
                )}
                <div className={styles.triageActionsContainer}>
                  {isDeleted ? (
                    <TrashActionButton
                      docId={id}
                      docDocumentLocation={documentLocation}
                      actionDelay={200}
                      beforeAction={setIsMovingTo}
                      afterAction={() => {
                        if (isFeedList) {
                          markDocumentAsOpened(id);
                        }
                        setIsMovingTo('');
                      }}
                    />
                  ) : (
                    <DocumentActionButtons
                      docId={id}
                      docDocumentLocation={documentLocation}
                      actionDelay={200}
                      beforeAction={setIsMovingTo}
                      afterAction={() => {
                        if (isFeedList) {
                          markDocumentAsOpened(id);
                        }
                        setIsMovingTo('');
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </DocumentListItemWrapper>
    </li>
  );
};

export const DocumentListItem = React.memo(
  _DocumentListItem,
  // TODO: perhaps we want to optimize this later, by comparing only select props
);
